import { CheckInput } from '@archinsurance-viki/property-jslib/src/components/inputs';
import * as React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import PagedTableContainer from '@archinsurance-viki/property-jslib/src/containers/PagedTableContainer';
import BottomPanelContainer from './BottomPanelContainer';
import { SubmissionType, InboxType, ApiStatusType, UserType } from '../../ts-types/DataTypes';
import { ActionPanelActionType, PagedTableDataType, RowSelectedFnType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { Types } from '../../ts-types/icubed-types';
import DuplicateSubmissionButton from '../buttons/DuplicateSubmissionButton';

import {
    FORWARD_ACTION,
    REPLY_ACTION,
    CLEAR_ACTION,
    BLOCK_ACTION,
    ACTIONS_MAPPING,
    DECLINED_ACTION,
    CLEARANCE_STATUS,
    CANT_CLEAR_TOOLTIP,
    CANT_BLOCK_TOOLTIP,
    RESOLVE_PENDING_BOR_ACTION,
    RELEASE_PENDING_APPROVAL,
    PENDING_BOR_STATUSES,
} from '../../constants/Constants';

import { ICUBED_MODAL_TYPES, DUPLICATE_MODAL_WIDTH, BOR_MATCHING_MODAL_WIDTH, DEBUG_INFO_MODAL_WIDTH } from '../../constants/Modals';

import { PAGED_TABLES } from '../../constants/PagedTableConfigs';
import UnderwritingPlatformToggle from '../buttons/UnderwritingPlatformToggle';
import { captureMessageThen } from '@archinsurance-viki/property-jslib/src/utils//ui-helpers';
import ConfirmPromptButton from '@archinsurance-viki/property-jslib/src/components/popups/ConfirmPrompt';
import { getConfictsPanel, getValidationErrorsPanel, ValidationRefetchWrapper } from '../../utils/account-and-submission-conflct-utils';
import ComboBoxComponent from '@archinsurance-viki/property-jslib/src/components/select/ComboBoxComponent';
import {
    VMAC_BLOCK_CLICK_EVENT,
    VMAC_CLEAR_CLICK_EVENT,
    VMAC_DECLINE_CLICK_EVENT,
    VMAC_FORWARD_CLICK_EVENT,
    VMAC_REPLY_CLICK_EVENT,
    VMAC_MARK_PENDING_BOR_CLICK_EVENT,
    VMAC_RESOLVE_PENDING_BOR_CLICK_EVENT,
    VMAC_RELEASE_PENDING_APPROVAL_CLICK_EVENT,
} from '../../constants/AnalyticsConstants';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import { CENTERED_MODAL_TYPES } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { ALL_COLUMNS_LIST, DEFAULT_TABLE_COLUMNS } from '../../constants/PagedTableViewSettings';

const TABLE_CONFIG = PAGED_TABLES.SUBMISSIONS;

type propTypes = {
    currentSubmission?: SubmissionType;
    currentSubmissionId?: number;
    tableData: PagedTableDataType;
    inboxes: InboxType[];
    currentInbox: InboxType;
    uiState: any;
    me: UserType;
    CONSTANTS: Types.Constants;
    ENV: Types.Env;
    featureFlags: Record<string, boolean>;

    _arch_integration: {
        submissionStatuses: ApiStatusType[];
        accountStatuses: ApiStatusType[];
        apiStatuses: Record<string, any>;
    };

    onTakeAction: (id: number, action: string, params: Record<string, any>, sendEmailNotification: boolean, archClearanceAPIRelatedSID: string) => void;
    onPopEmailForwardModal: (params: Record<string, any>) => void;
    onPopResponseModal: (params: Record<string, any>) => void;
    onRowSelected: RowSelectedFnType;
    onOpenCenteredModal: (params: Record<string, any>, modalType: string) => void;
    setUiState: (params: Record<string, any>, path: string) => void;
    onBatchSaveFn: (field: string, value: any) => void;
    onToggleAllCheckedRows: () => void;
    onSaveFn: (fieldValueMap: any) => Promise<any>;
    openConflictTable: (_sid: any, rowData: SubmissionType) => void;
    onClickDuplicateInTable: (sid: number, rowIndex: number, rowData: any) => void;
    onCreateFakeSMMSSubmission: () => void;
    onCreateFakeArchRenewalSubmission: () => void;
    onOpenMessageModal: (params: Record<string, any>, modalType: string) => void;
};

type stateTypes = {
    showOldSubmissions: boolean;
    clearDropdownOpen: boolean;
    blockDropdownOpen: boolean;
    declineDropdownOpen: boolean;
    resolvePendingBorDropdownOpen: boolean;
    releasePendingApprovalDropdownOpen: boolean;
};

export default class InboxPanel extends React.Component<propTypes, stateTypes> {
    static contextType = AppContext;
    archiveButtonRef: any;
    selectedBatchAssignToIndex: number;
    constructor(props: propTypes) {
        super(props);
        this.archiveButtonRef = React.createRef();
        this.selectedBatchAssignToIndex = -1;
        this.state = {
            showOldSubmissions: false,
            clearDropdownOpen: false,
            blockDropdownOpen: false,
            declineDropdownOpen: false,
            resolvePendingBorDropdownOpen: false,
            releasePendingApprovalDropdownOpen: false,
        };
    }

    componentDidUpdate(prevProps: propTypes) {
        if (prevProps.currentSubmissionId !== this.props.currentSubmissionId) {
            this.setState({
                declineDropdownOpen: false,
                clearDropdownOpen: false,
                blockDropdownOpen: false,
                resolvePendingBorDropdownOpen: false,
                releasePendingApprovalDropdownOpen: false,
            });
        }
    }

    getSubmissionInbox = (submission: SubmissionType): InboxType => {
        const { inboxes } = this.props;
        if (inboxes.length > 1) return this.props.inboxes.filter(i => i.id === submission.inbox_id)[0];
        return inboxes[0];
    };

    handleArchiveOrRestoreSubmission = (nextAction: string) => {
        const { currentSubmission } = this.props;
        if (currentSubmission) {
            if (nextAction === CLEARANCE_STATUS.ARCHIVED) {
                const postArchiveFn = fieldValueMap => {
                    console.log(fieldValueMap);
                    let arch_clearance_api_related_sid = fieldValueMap?.request?.data?.arch_clearance_api_related_sid;
                    this.props.onTakeAction(currentSubmission.id, nextAction, {}, false, arch_clearance_api_related_sid);
                    this.handleClickAndMoveToNewRow();
                };
                const submissionInbox = this.getSubmissionInbox(currentSubmission);
                if (submissionInbox.imageright_enabled) {
                    const onPromptClosed = result => {
                        let fieldValueMap = {};
                        if (result) {
                            // if user clicks OK
                            fieldValueMap['arch_clearance_api_related_sid'] = result.message;
                            fieldValueMap['skip_attachments_on_imageright_upload'] = result.skip_attachments;
                        } else {
                            // user cancels
                            fieldValueMap['did_skip_archlink_sid_override_on_archive'] = true;
                        }
                        this.props.onSaveFn(fieldValueMap).then(fieldValueMap => {
                            postArchiveFn(fieldValueMap);
                        });
                    };

                    const extraChildren = (
                        <CheckInput
                            name="skip_attachments"
                            label="Skip Attachments (only send correspondence)"
                            style={{ 'align-self': 'flex-start', 'text-align': 'left' }}
                            labelAfter={true}
                            disabled={false}
                            object={{ skip_attachments: false }}
                        />
                    );
                    captureMessageThen({
                        required: true,
                        title: 'Archiving',
                        prompt: 'SID for ImageRight(Archlink/VIKI)',
                        onSave: onPromptClosed,
                        onCancel: onPromptClosed,
                        saveButtonCaption: 'Send Documents',
                        cancelButtonCaption: "Don't Send Documents",
                        showCloseButton: true,
                    })(extraChildren, this.archiveButtonRef.current);
                } else {
                    postArchiveFn({});
                }
            } else {
                this.props.onTakeAction(currentSubmission.id, nextAction, {}, false, null);
                this.handleClickAndMoveToNewRow();
            }
        }
    };

    _canOverrideValidationRules() {
        const { can_override_arch_rules, can_override_business_rules } = this.props.me;
        return can_override_arch_rules || can_override_business_rules;
    }

    _hasValidationErrorsForAction(action: any) {
        let { currentSubmission, me } = this.props;
        if (!currentSubmission || !me) {
            return false;
        }

        let validations = currentSubmission._validations || {};
        let errors = {};
        if (action === CLEAR_ACTION) {
            errors = validations.CLEAR || {};
        } else if (action === BLOCK_ACTION) {
            errors = validations.BLOCK || {};
        } else if (action === DECLINED_ACTION) {
            errors = validations.DECLINE || {};
        }
        return Object.keys(errors).length > 0 ? true : false;
    }

    handlePopResponse(action: any, sendEmailNotification: boolean) {
        const { currentSubmission, onPopResponseModal, onTakeAction } = this.props;
        const canAndShouldOverride =
            (this._hasValidationErrorsForAction(action) && this._canOverrideValidationRules()) ||
            (action === CLEAR_ACTION && currentSubmission.is_submitted_too_early);
        const is_renew_action = action === CLEAR_ACTION && currentSubmission.is_renewal_linked;

        if (sendEmailNotification) {
            let additionalCCEmails = [];
            const differentOriginatingUW =
                currentSubmission.originating_underwriter_email &&
                currentSubmission.originating_underwriter_email !== currentSubmission.request_underwriter_email;

            // NOTE: temporarily commented out for VIKI-8310
            if (action === BLOCK_ACTION) {
                additionalCCEmails.push(currentSubmission.request_underwriter_email);
                if (differentOriginatingUW) {
                    additionalCCEmails.push(currentSubmission.originating_underwriter_email);
                }
            }

            const overrideCallbackFn = (action: string) => {
                onPopResponseModal({
                    action,
                    additionalCCEmails: additionalCCEmails,
                    currentSubmissionId: currentSubmission.id,
                    replyToEmail: (currentSubmission || {}).reply_to_email,
                    currentInbox: this.getSubmissionInbox(currentSubmission),
                    vikiSid: currentSubmission.external_sid,
                    archSid: currentSubmission.arch_clearance_api_submission_id,
                    submissionWasActioned: currentSubmission.status === 'C' || currentSubmission.status === 'D' || currentSubmission.status === 'B',
                });
            };
            if (canAndShouldOverride && !is_renew_action) {
                this.overrideBusinessRules(action, overrideCallbackFn);
            } else {
                overrideCallbackFn(action);
            }
        } else {
            const overrideCallbackFn = onTakeAction.bind(this, currentSubmission.id, ACTIONS_MAPPING[action], {}, false, null);
            if (canAndShouldOverride && !is_renew_action) {
                this.overrideBusinessRules(action, overrideCallbackFn);
            } else {
                overrideCallbackFn();
            }
        }

        let actionEvent;
        switch (action) {
            case BLOCK_ACTION:
                actionEvent = VMAC_BLOCK_CLICK_EVENT;
                break;
            case CLEAR_ACTION:
                actionEvent = VMAC_CLEAR_CLICK_EVENT;
                break;
            case DECLINED_ACTION:
                actionEvent = VMAC_DECLINE_CLICK_EVENT;
                break;
            case REPLY_ACTION:
                actionEvent = VMAC_REPLY_CLICK_EVENT;
                break;
            default:
                actionEvent = '';
        }

        if (actionEvent) {
            this.context.analytics.track(actionEvent, {
                ...this.getAnalyticsProps(),
                send_email: sendEmailNotification,
            });
        }
    }

    handlePopEmailForwardModal = (action: any) => {
        const { currentSubmission, currentSubmissionId } = this.props;
        this.props.onPopEmailForwardModal({
            action: action,
            currentSubmissionId: currentSubmissionId,
            externalSid: currentSubmission.external_sid,
            emailSubject: currentSubmission.email_subject,
            replyToEmail: (currentSubmission || {}).reply_to_email,
            submissionUWP: currentSubmission.underwriting_platform,
            currentInbox: this.getSubmissionInbox(currentSubmission),
            archSid: currentSubmission.arch_clearance_api_submission_id,
            submissionWasActioned: currentSubmission.status === 'C' || currentSubmission.status === 'D' || currentSubmission.status === 'B',
        });

        this.context.analytics.track(VMAC_FORWARD_CLICK_EVENT, {
            ...this.getAnalyticsProps(),
            arch_sid: currentSubmission.arch_clearance_api_submission_id,
        });
    };

    handleMarkPendingBor = (sendEmailNotification: boolean) => {
        const { tableData, currentSubmission } = this.props;
        this.props.onOpenCenteredModal(
            {
                tableData,
                width: BOR_MATCHING_MODAL_WIDTH,
                currentSubmission: currentSubmission,
                onClickDuplicateInTable: this.props.onClickDuplicateInTable,
                sendEmailNotification: sendEmailNotification,
            },
            ICUBED_MODAL_TYPES.BOR_SUBMISSION_MATCHING
        );

        this.context.analytics.track(VMAC_MARK_PENDING_BOR_CLICK_EVENT, this.getAnalyticsProps());
    };

    handleBlockSubmission = (sendEmailNotification: boolean) => {
        const { currentSubmission, onTakeAction, featureFlags } = this.props;
        const canAndShouldOverride = this._hasValidationErrorsForAction(BLOCK_ACTION) && this._canOverrideValidationRules();
        if (sendEmailNotification) {
            const overrideCallbackFn = () => {
                this.props.onOpenCenteredModal(
                    {
                        currentSubmission,
                        modalData: {
                            currentSubmissionId: currentSubmission.id,
                            action: BLOCK_ACTION,
                            should_use_arch_underwriters: featureFlags.enable_uw_list_ff && !currentSubmission.request_underwriter_id,
                        },
                    },
                    ICUBED_MODAL_TYPES.BLOCK_SUBMISSION
                );
            };
            if (canAndShouldOverride) {
                this.overrideBusinessRules(BLOCK_ACTION, overrideCallbackFn);
            } else {
                overrideCallbackFn();
            }
        } else {
            const overrideCallbackFn = onTakeAction.bind(this, currentSubmission.id, ACTIONS_MAPPING[BLOCK_ACTION], {}, false, null);
            if (canAndShouldOverride) {
                this.overrideBusinessRules(BLOCK_ACTION, overrideCallbackFn);
            } else {
                overrideCallbackFn();
            }
        }
        this.context.analytics.track(VMAC_BLOCK_CLICK_EVENT, this.getAnalyticsProps());
    };

    handleResolvePendingBor = (sendEmailNotification: boolean) => {
        const { currentSubmission, setUiState } = this.props;
        if (currentSubmission && currentSubmission.bor_incumbent_submission_id) {
            setUiState({ shouldSend: sendEmailNotification }, 'emailNotification');
            this.props.onOpenCenteredModal(
                {
                    currentSubmission,
                    modalData: {
                        sendEmailNotification,
                        action: RESOLVE_PENDING_BOR_ACTION,
                        currentSubmissionId: currentSubmission.id,
                    },
                },
                ICUBED_MODAL_TYPES.RESOLVE_PENDING_BOR
            );
        }
        this.context.analytics.track(VMAC_RESOLVE_PENDING_BOR_CLICK_EVENT, this.getAnalyticsProps());
    };

    UnmarkPendingBorConfirmationBotton = currentSubmission => {
        this.props.onOpenCenteredModal(
            {
                title: `Are you sure you'd like to undo this BOR?`,
                modalData: {
                    confirmLabelTitle: 'Proceed',
                    dismissButtonTitle: 'Cancel',
                    onOk: () => {
                        this.props.onTakeAction(currentSubmission.id, ACTIONS_MAPPING.submission_unmark_pending_bor, null, null, null);
                    },
                },
            },
            CENTERED_MODAL_TYPES.CONFIRM
        );
    };

    handleReleaseBorApproval = (sendEmailNotification: boolean) => {
        const { currentSubmission, setUiState } = this.props;
        if (currentSubmission) {
            setUiState({ shouldSend: sendEmailNotification }, 'emailNotification');
            this.props.onOpenCenteredModal(
                {
                    currentSubmission,
                    modalData: {
                        sendEmailNotification,
                        action: RELEASE_PENDING_APPROVAL,
                        currentSubmissionId: currentSubmission.id,
                    },
                },
                ICUBED_MODAL_TYPES.RELEASE_PENDING_APPROVAL
            );
        }
        this.context.analytics.track(VMAC_RELEASE_PENDING_APPROVAL_CLICK_EVENT, this.getAnalyticsProps());
    };

    overrideBusinessRules = (action: any, performClearanceAction: (reason: string) => void) => {
        let { tableData, currentSubmission } = this.props;
        this.props.onOpenCenteredModal(
            {
                currentSubmission,
                tableData,
                performClearanceAction,
                action,
            },
            ICUBED_MODAL_TYPES.OVERRIDE_BUSINESS_RULES
        );
    };

    goToDupe = (_sid: any) => {
        let { tableData, currentSubmission } = this.props;
        this.props.onOpenCenteredModal(
            {
                currentSubmission: currentSubmission,
                tableData: tableData,
                onClickDuplicateInTable: this.props.onClickDuplicateInTable,
                width: DUPLICATE_MODAL_WIDTH,
            },
            ICUBED_MODAL_TYPES.DUPLICATE_SUBMISSIONS
        );
    };

    handleClickAndMoveToNewRow = () => {
        let { tableData, currentSubmissionId } = this.props;
        const currentIndexInTable = tableData.rows.indexOf(currentSubmissionId);
        if (currentIndexInTable !== undefined && tableData.rows[currentIndexInTable + 1] !== undefined) {
            this.props.onRowSelected(tableData.rows[currentIndexInTable + 1] as number);
        }
    };

    getFilterPanelState() {
        return !!_.get(this.props.uiState, `tableStates.${TABLE_CONFIG.tableName}.filterColumnsActive`);
    }

    onToggleFilterColumns = () => {
        const newState = {
            filterColumnsActive: !this.getFilterPanelState(),
        };
        this.props.setUiState(newState, `tableStates.${TABLE_CONFIG.tableName}`);
    };

    getAnalyticsProps() {
        return { inbox_id: this.props.currentInbox.id, submission_id: this.props.currentSubmissionId };
    }

    getDynamicButton(currentSubmission?: SubmissionType) {
        let isRenewal = currentSubmission ? currentSubmission.is_renewal_linked : false;
        let isDisabled = true;
        let nextActionLabel = null;
        let nextAction = CLEARANCE_STATUS.NONE;
        const isSMMSSubmission = currentSubmission && currentSubmission.inbox_id === this.props.CONSTANTS.INBOX_IDS.SMMS;
        const validations = currentSubmission && currentSubmission._validations ? currentSubmission._validations : {};
        const isOFACError = validations.WARN?.ofac_check_result ? validations.WARN?.ofac_check_result.length > 0 : false;

        if (currentSubmission.has_new_message) {
            // only option is dismiss and only enabled when there is a new message
            isDisabled = !currentSubmission.has_new_message;
            // isRenewal disables the dismiss button, same as isDisabled
            // should be active for renewals as well
            isRenewal = isDisabled;
            nextActionLabel = 'DISMISS';
            nextAction = CLEARANCE_STATUS.DISMISS;
        } else {
            isDisabled = currentSubmission.unactioned_auto_suggested_action;
            nextActionLabel = !currentSubmission.is_archived ? 'ARCHIVE' : 'RESTORE';
            nextAction = !currentSubmission.is_archived ? CLEARANCE_STATUS.ARCHIVED : CLEARANCE_STATUS.RESTORE;
        }

        if ((currentSubmission.assigned_to_id && currentSubmission.assigned_to_id !== this.props.me.id) || isSMMSSubmission) {
            isDisabled = true;
        }

        isDisabled = isDisabled || isRenewal || isOFACError;

        return (
            <ConfirmPromptButton
                confirmPromptProps={{
                    onConfirmed: this.handleArchiveOrRestoreSubmission.bind(this, nextAction),
                    confirmButtonTitle: nextActionLabel,
                    title: `Are you sure you want to ${nextActionLabel} this submission?`,
                }}
                className="red"
                disabled={isDisabled}
                buttonRef={nextAction === CLEARANCE_STATUS.ARCHIVED ? this.archiveButtonRef : null}
            >
                {nextActionLabel}
            </ConfirmPromptButton>
        );
    }

    toggleClearButtonDropdown() {
        this.setState({
            clearDropdownOpen: !this.state.clearDropdownOpen,
            blockDropdownOpen: false,
            declineDropdownOpen: false,
            resolvePendingBorDropdownOpen: false,
            releasePendingApprovalDropdownOpen: false,
        });
    }

    toggleBlockButtonDropdown() {
        this.setState({
            blockDropdownOpen: !this.state.blockDropdownOpen,
            clearDropdownOpen: false,
            declineDropdownOpen: false,
            resolvePendingBorDropdownOpen: false,
            releasePendingApprovalDropdownOpen: false,
        });
    }

    toggleDeclineButtonDropdown() {
        this.setState({
            declineDropdownOpen: !this.state.declineDropdownOpen,
            blockDropdownOpen: false,
            clearDropdownOpen: false,
            resolvePendingBorDropdownOpen: false,
            releasePendingApprovalDropdownOpen: false,
        });
    }

    togglePendingBorButtonDropdown() {
        this.setState({
            resolvePendingBorDropdownOpen: !this.state.resolvePendingBorDropdownOpen,
            declineDropdownOpen: false,
            blockDropdownOpen: false,
            clearDropdownOpen: false,
            releasePendingApprovalDropdownOpen: false,
        });
    }

    toggleReleasePendingApprovalButtonDropdown() {
        this.setState({
            releasePendingApprovalDropdownOpen: !this.state.releasePendingApprovalDropdownOpen,
            declineDropdownOpen: false,
            blockDropdownOpen: false,
            clearDropdownOpen: false,
            resolvePendingBorDropdownOpen: false,
        });
    }
    getStatusActionButton(currentSubmission: SubmissionType, action: string) {
        const status = currentSubmission ? currentSubmission.status : null;
        const isRenewal = currentSubmission ? currentSubmission.is_renewal_linked : false;
        const buttonsDisabled =
            !currentSubmission ||
            status === null ||
            status === undefined ||
            (currentSubmission.assigned_to_id && currentSubmission.assigned_to_id !== this.props.me.id);
        const validations = currentSubmission && currentSubmission._validations ? currentSubmission._validations : {};
        const clearValidationError = validations.CLEAR ? Object.keys(validations.CLEAR).length > 0 : false;
        const blockValidationError = validations.BLOCK ? Object.keys(validations.BLOCK).length > 0 : false;
        const declineValidationError = validations.DECLINE ? Object.keys(validations.DECLINE).length > 0 : false;
        const isSMMSSubmission = currentSubmission && currentSubmission.inbox_id === this.props.CONSTANTS.INBOX_IDS.SMMS;
        const isOFACError = validations.WARN?.ofac_check_result ? validations.WARN?.ofac_check_result.length > 0 : false;

        // common
        let buttonTooltip = null;
        let isDisabled = null;
        let canAction = null;
        let clearButtonLabel = 'Clear';
        switch (action) {
            case CLEAR_ACTION:
                canAction =
                    currentSubmission &&
                    currentSubmission.id &&
                    status === CLEARANCE_STATUS.UNPROCESSED &&
                    !currentSubmission.has_sent_submission_cleared_email &&
                    !clearValidationError &&
                    !currentSubmission.is_archived &&
                    (!currentSubmission.unactioned_auto_suggested_action ||
                        (currentSubmission.unactioned_auto_suggested_action && currentSubmission.assigned_to_id === this.props.me.id));
                buttonTooltip = canAction ? '' : CANT_CLEAR_TOOLTIP;
                isDisabled = this.clearIsDisabled(!canAction || buttonsDisabled || status === CLEARANCE_STATUS.CLEARED || isOFACError);
                clearButtonLabel = currentSubmission.is_renewal_linked ? 'Renewal' : 'Clear';

                return (
                    <button
                        className="blue"
                        title={buttonTooltip}
                        disabled={isDisabled}
                        onClick={this.toggleClearButtonDropdown.bind(this)}
                        data-testid="clear-button"
                    >
                        {clearButtonLabel}
                        <div
                            className={classNames('button-menu blue', {
                                active: this.state.clearDropdownOpen,
                            })}
                        >
                            {!isRenewal && !isSMMSSubmission && (
                                <>
                                    <a onClick={this.handlePopResponse.bind(this, CLEAR_ACTION, true)}>Clear and send email</a>
                                    <span></span>
                                </>
                            )}
                            <a onClick={this.handlePopResponse.bind(this, CLEAR_ACTION, false)}>{isRenewal ? 'Renew without email' : 'Clear without email'}</a>
                        </div>
                    </button>
                );
            case BLOCK_ACTION:
                canAction =
                    !blockValidationError &&
                    currentSubmission &&
                    currentSubmission.id &&
                    status !== CLEARANCE_STATUS.BLOCKED &&
                    status !== CLEARANCE_STATUS.DECLINED &&
                    status !== CLEARANCE_STATUS.CLEARED &&
                    !currentSubmission.has_sent_submission_blocked_email &&
                    !currentSubmission.is_archived &&
                    (!currentSubmission.unactioned_auto_suggested_action ||
                        (currentSubmission.unactioned_auto_suggested_action && currentSubmission.assigned_to_id === this.props.me.id));
                buttonTooltip = canAction ? '' : CANT_BLOCK_TOOLTIP;
                isDisabled =
                    this.blockIsDisabled(!canAction || buttonsDisabled || status === CLEARANCE_STATUS.BLOCKED) || isSMMSSubmission || isRenewal || isOFACError;
                return (
                    <button
                        className="pink"
                        title={buttonTooltip}
                        disabled={isDisabled}
                        onClick={this.toggleBlockButtonDropdown.bind(this)}
                        data-testid="block-button"
                    >
                        Block
                        <div
                            className={classNames('button-menu pink', {
                                active: this.state.blockDropdownOpen,
                            })}
                        >
                            <a onClick={this.handleBlockSubmission.bind(this, true)}>Block and send email</a>
                            <span></span>
                            <a onClick={this.handleBlockSubmission.bind(this, false)}>Block without email</a>
                        </div>
                    </button>
                );
            case DECLINED_ACTION:
                canAction =
                    !declineValidationError &&
                    currentSubmission &&
                    currentSubmission.id &&
                    status !== CLEARANCE_STATUS.BLOCKED &&
                    status !== CLEARANCE_STATUS.DECLINED &&
                    status !== CLEARANCE_STATUS.CLEARED &&
                    !currentSubmission.has_sent_submission_declined_email &&
                    !currentSubmission.is_archived &&
                    (!currentSubmission.unactioned_auto_suggested_action ||
                        (currentSubmission.unactioned_auto_suggested_action && currentSubmission.assigned_to_id === this.props.me.id));

                isDisabled =
                    this.declinedIsDisabled(!canAction || buttonsDisabled || status === CLEARANCE_STATUS.DECLINED) ||
                    isSMMSSubmission ||
                    isRenewal ||
                    isOFACError;
                return (
                    <button
                        className="grey-dark"
                        title=""
                        disabled={isDisabled}
                        onClick={this.toggleDeclineButtonDropdown.bind(this)}
                        data-testid="decline-button"
                    >
                        Decline
                        <div
                            className={classNames('button-menu grey', {
                                active: this.state.declineDropdownOpen,
                            })}
                        >
                            <a onClick={this.handlePopResponse.bind(this, DECLINED_ACTION, true)}>Decline and send email</a>
                            <span></span>
                            <a onClick={this.handlePopResponse.bind(this, DECLINED_ACTION, false)}>Decline without email</a>
                        </div>
                    </button>
                );
            default:
                break;
        }
    }

    clearIsDisabled = (disableLogic: boolean) => {
        let { currentSubmission, me } = this.props;
        if (!currentSubmission || !me) {
            return disableLogic;
        }

        // some fields are required even for overriding so first check that there are no override errors
        let validations = currentSubmission._validations || {};
        let hasOverrideErrors = Object.keys(validations.OVERRIDE || {}).length > 0 ? true : false;
        if (hasOverrideErrors) {
            return true;
        }

        // if there are clear errors and user has permission to override then let them
        let hasClearErrors = Object.keys(validations.CLEAR || {}).length > 0 ? true : false;
        if (hasClearErrors && currentSubmission.status === CLEARANCE_STATUS.UNPROCESSED) {
            if ('id' in validations.CLEAR && validations.CLEAR.id.includes('International ARCH conflict. We cannot clear this account')) {
                if (me.can_override_arch_rules) {
                    return false;
                } else {
                    return true;
                }
            } else if ('id' in validations.CLEAR && validations.CLEAR.id.includes('A valid ProducerId is required')) {
                return true;
            }
            if (me.can_override_business_rules) {
                return false;
            }
        }

        return disableLogic;
    };

    blockIsDisabled = (disableLogic: boolean) => {
        let { currentSubmission, me } = this.props;
        if (!currentSubmission || !me) {
            return disableLogic;
        }

        // some fields are required even for overriding so first check that there are no override errors
        let validations = currentSubmission._validations || {};
        let hasOverrideErrors = Object.keys(validations.OVERRIDE || {}).length > 0 ? true : false;
        if (hasOverrideErrors) {
            return true;
        }

        // if there are block errors, status is valid and user has permission to override then let them
        let hasBlockErrors = Object.keys(validations.BLOCK || {}).length > 0 ? true : false;
        let hasValidStatus = currentSubmission.status === CLEARANCE_STATUS.UNPROCESSED || currentSubmission.status === CLEARANCE_STATUS.CLEARED;
        if (hasBlockErrors && me.can_override_business_rules && hasValidStatus) {
            return false;
        }

        return disableLogic;
    };

    declinedIsDisabled = (disableLogic: boolean) => {
        let { currentSubmission, me } = this.props;
        if (!currentSubmission || !me) {
            return disableLogic;
        }

        // some fields are required even for overriding so first check that there are no override errors
        let validations = currentSubmission._validations || {};
        let hasOverrideErrors = Object.keys(validations.OVERRIDE || {}).length > 0 ? true : false;

        if (hasOverrideErrors && currentSubmission.assigned_research !== 'U' && currentSubmission.assigned_research !== 'D') {
            // should be allowed to decline no matter what when assigned research is "Unappointed Broker" OR "> 120 Days DTI"
            return true;
        }

        // if there are decline errors, status is valid and user has permission to override then let them
        let hasBlockErrors = Object.keys(validations.DECLINE || {}).length > 0 ? true : false;
        let hasValidStatus = currentSubmission.status === CLEARANCE_STATUS.UNPROCESSED || currentSubmission.status === CLEARANCE_STATUS.CLEARED;
        if (hasBlockErrors && me.can_override_business_rules && hasValidStatus) {
            return false;
        }

        return disableLogic;
    };

    getBorActionButton = (submission: SubmissionType): React.ReactNode => {
        const validations = submission && submission._validations ? submission._validations : {};
        const markPendingBorValidationError = validations.MARK_PENDING_BOR ? Object.keys(validations.MARK_PENDING_BOR).length > 0 : false;
        const resolvePendingBorValidationError = validations.RESOLVE_PENDING_BOR ? Object.keys(validations.RESOLVE_PENDING_BOR).length > 0 : false;
        const isOFACError = validations.WARN?.ofac_check_result ? validations.WARN?.ofac_check_result.length > 0 : false;

        const canAction =
            submission &&
            submission.pending_bor_status !== undefined &&
            submission.id &&
            !submission.is_archived &&
            (!submission.assigned_to_id || submission.assigned_to_id === this.props.me.id) &&
            !submission.is_renewal_linked &&
            !isOFACError;
        const canMarkPendingBor = canAction && !markPendingBorValidationError;
        const canResolvePendingBor = canAction && submission.bor_incumbent_submission_id !== undefined && !resolvePendingBorValidationError;
        const canReleaseBORPriorToApproval = canAction;

        if (submission.pending_bor_status === undefined || submission.pending_bor_status === null) {
            return (
                <button className="green" disabled={!canMarkPendingBor} onClick={this.handleMarkPendingBor.bind(this, true)} data-testid="bor-button">
                    Pending BOR
                </button>
            );
        } else if (submission.pending_bor_status === PENDING_BOR_STATUSES.PENDING_APPROVAL) {
            return (
                <button
                    className="green"
                    disabled={!canReleaseBORPriorToApproval}
                    onClick={this.toggleReleasePendingApprovalButtonDropdown.bind(this)}
                    data-testid="bor-button"
                >
                    Release BOR
                    <div
                        className={classNames('button-menu green', {
                            active: this.state.releasePendingApprovalDropdownOpen,
                        })}
                    >
                        <a onClick={this.handleReleaseBorApproval.bind(this, true)}>Release and send email</a>
                        <span></span>
                        <a onClick={this.handleReleaseBorApproval.bind(this, false)}>Release without email</a>
                        <span></span>
                        <a onClick={this.UnmarkPendingBorConfirmationBotton.bind(this, submission)}>Unmark Pending BOR</a>
                    </div>
                </button>
            );
        } else {
            return (
                <button className="green" disabled={!canResolvePendingBor} onClick={this.togglePendingBorButtonDropdown.bind(this)} data-testid="bor-button">
                    Release BOR
                    <div
                        className={classNames('button-menu green', {
                            active: this.state.resolvePendingBorDropdownOpen,
                        })}
                    >
                        <a onClick={this.handleResolvePendingBor.bind(this, true)}>Release and send email</a>
                        <span></span>
                        <a onClick={this.handleResolvePendingBor.bind(this, false)}>Release without email</a>
                    </div>
                </button>
            );
        }
    };

    renderRowActions(currentSubmission?: SubmissionType) {
        const { me, currentInbox } = this.props;
        const status = currentSubmission ? currentSubmission.status : null;
        const isRenewal = currentSubmission ? currentSubmission.is_renewal_linked : false;
        const buttonsDisabled =
            !currentSubmission ||
            status === null ||
            status === undefined ||
            (currentSubmission.assigned_to_id && currentSubmission.assigned_to_id !== me.id) ||
            isRenewal;
        const canExportToVIKI = !buttonsDisabled && currentSubmission;
        const canReply = currentSubmission && currentSubmission.id && currentSubmission.reply_to_email;

        const validations = currentSubmission && currentSubmission._validations ? currentSubmission._validations : {};
        const isOFACError = validations.WARN?.ofac_check_result ? validations.WARN?.ofac_check_result.length > 0 : false;

        let pendingBorEnabled = false;
        if (currentSubmission && currentInbox && currentSubmission.inbox_id) {
            pendingBorEnabled = currentInbox.id === -1 ? this.getSubmissionInbox(currentSubmission).pending_bor_enabled : currentInbox.pending_bor_enabled;
        }

        const gridLayoutCount = pendingBorEnabled ? '7' : '6'; // feature flag VIKI-7007

        return (
            <div className={`row-actions grid-layout gl-${gridLayoutCount}`} data-testid="submission-action-buttons">
                {this.getStatusActionButton(currentSubmission, CLEAR_ACTION)}
                {this.getStatusActionButton(currentSubmission, BLOCK_ACTION)}
                <button
                    disabled={buttonsDisabled || !canReply || isOFACError}
                    className="purple"
                    onClick={this.handlePopResponse.bind(this, REPLY_ACTION) as any}
                    data-testid="reply-button"
                >
                    Reply
                </button>
                {this.getStatusActionButton(currentSubmission, DECLINED_ACTION)}
                {this.getDynamicButton(currentSubmission)}
                <If condition={pendingBorEnabled}>{this.getBorActionButton(currentSubmission)}</If>
                <button
                    className="green"
                    disabled={!canExportToVIKI || isOFACError}
                    onClick={this.handlePopEmailForwardModal.bind(this, FORWARD_ACTION)}
                    data-testid="forward-button"
                >
                    Forward
                </button>
            </div>
        );
    }

    renderSubmissionAssignmentControl = () => {
        const { me, onBatchSaveFn, onToggleAllCheckedRows, CONSTANTS, tableData } = this.props;
        if (!me.can_assign_submissions || _.isEmpty(tableData.checkedRows)) {
            return null;
        }
        const users: [string, string][] = [[null, 'Unassigned'], ..._.sortBy(CONSTANTS.CLEARANCE_USERS, c => c[1])];
        return (
            <div
                className={classNames('icon', {
                    enabled: me.can_assign_submissions,
                })}
                title="Assign user to submissions"
            >
                <i className="material-icons show-others-viewing-icon">supervised_user_circle</i>
                <ComboBoxComponent
                    onSelected={async (id: any, value: any) => {
                        this.selectedBatchAssignToIndex = users.findIndex(user => user[0] === id && user[1] === value);
                        await onBatchSaveFn('assigned_to_id', id);
                        onToggleAllCheckedRows();
                        this.selectedBatchAssignToIndex = -1;
                    }}
                    choices={users}
                    selectedIndex={this.selectedBatchAssignToIndex}
                />
            </div>
        );
    };

    generateViewSettingsOverride = (dynamicConfig: Record<string, any>) => {
        const { currentInbox, featureFlags, CONSTANTS } = this.props;

        const viewSettingsConfigCopy = _.cloneDeep(TABLE_CONFIG.viewSettingsConfig);
        if (viewSettingsConfigCopy.type !== 'tabs') return;
        // respect pending_bor_enabled feature flag VIKI-7007

        if (!featureFlags.needs_reconciliation || !currentInbox) {
            viewSettingsConfigCopy.tabsList = viewSettingsConfigCopy.tabsList.filter(p => p.id !== 'needs_reconciliation');
        }

        if (this.state.showOldSubmissions) {
            viewSettingsConfigCopy.tablePropsDefaults.apiParams.date_received__gte = null;
        }

        if (currentInbox.id === CONSTANTS.INBOX_IDS.RETAIL || currentInbox.id === CONSTANTS.INBOX_IDS.SMMS) {
            viewSettingsConfigCopy.tablePropsDefaults.columnList = DEFAULT_TABLE_COLUMNS.retailSubmission;
            viewSettingsConfigCopy.allColumnsList = ALL_COLUMNS_LIST.retailSubmission;
        }

        dynamicConfig.viewSettingsConfig = viewSettingsConfigCopy;

        return { ...TABLE_CONFIG, ...dynamicConfig };
    };

    toggleShowOldSubmissions = () => {
        this.setState({ showOldSubmissions: !this.state.showOldSubmissions });
    };

    handleShowDebugPanel = () => {
        const { currentSubmission } = this.props;
        const props = {
            currentSubmission: currentSubmission,
            width: DEBUG_INFO_MODAL_WIDTH,
        };
        this.props.onOpenCenteredModal(props, ICUBED_MODAL_TYPES.DEBUG_INFO);
    };

    render() {
        const { me, currentSubmission, ENV, featureFlags, currentInbox, uiState } = this.props;
        const rowActions = this.renderRowActions(currentSubmission);

        let dynamicConfig: Record<string, any> = {};

        if (currentInbox && currentInbox.id) {
            if (currentInbox.id !== -1) {
                dynamicConfig = {
                    apiParams: {
                        inbox_id: currentInbox.id,
                    },
                };
            }
        } else {
            // DON't load table until we have an inbox id. No point.
            return <div className="left-half" />;
        }

        const tableConfig = this.generateViewSettingsOverride(dynamicConfig);

        let locations = [];
        if (currentSubmission && currentSubmission.buildings) {
            // TODO: This should be helper function and probably used by the INFOBLOCK that needs it.
            locations = currentSubmission.buildings.map(data => {
                if (!data) {
                    return {};
                }
                let { id, geo, street_address, city, state, zip_code, description } = data;
                return {
                    lat: geo && geo.coordinates && geo.coordinates[0],
                    lng: geo && geo.coordinates && geo.coordinates[1],
                    locationUnknown: false,
                    id,
                    address: `${street_address} ${city}, ${state} ${zip_code}`,
                    description,
                };
            });
        }

        const settingsActions: ActionPanelActionType[] = [
            {
                label: 'Column Filters',
                action: this.onToggleFilterColumns,
                active: this.getFilterPanelState(),
                id: 'toggleCFs',
            },
            {
                label: 'Download As CSV',
                action: (_data, _target, actionHelperFns) => actionHelperFns.onDownloadCSV(),
                active: false,
                id: 'downloadCSV',
            },
            {
                id: 'showOldSubmissions',
                label: 'Show Old Submissions',
                action: this.toggleShowOldSubmissions,
                active: this.state.showOldSubmissions,
            },
            {
                label: 'Audit Trail',
                action: this.handleShowDebugPanel,
                active: false,
                id: 'toggleDebugModal',
            },
        ];

        const bottomPanels: any = [
            {
                handleContent: [
                    <DuplicateSubmissionButton
                        currentSubmission={currentSubmission}
                        enableConflictAPICallOptimizationFF={this.props.featureFlags.enable_conflict_api_call_optimization_ff}
                    />,
                ],
                className: 'conflicts-status-panel',
                onClick: this.props.openConflictTable,
                hideIcons: true,
            },
            getValidationErrorsPanel(),
            getConfictsPanel({ _arch_integration: this.props._arch_integration }),
        ];

        if (ENV.ENV !== 'prod') {
            settingsActions.push({
                label: 'Create Fake SMMS Submission',
                action: this.props.onCreateFakeSMMSSubmission,
                active: false,
                id: 'createFakeSmmsSubmission',
            });
            settingsActions.push({
                label: 'Create Fake Arch Renewal Submission',
                action: this.props.onCreateFakeArchRenewalSubmission,
                active: false,
                id: 'createFakeArchRenewalSubmission',
            });
        }

        let pendingBorEnabled = false;
        if (currentSubmission && currentInbox && currentSubmission.inbox_id) {
            pendingBorEnabled = currentInbox.id === -1 ? this.getSubmissionInbox(currentSubmission).pending_bor_enabled : currentInbox.pending_bor_enabled;
        }
        const platformTogglerPanel = (
            <div className="filter-panel tw-max-w-[800px]">
                <UnderwritingPlatformToggle
                    key={`${currentSubmission.id}_${currentSubmission.underwriting_platform}`}
                    name="underwriting_platform"
                    onSaveFn={this.props.onSaveFn}
                    currentSubmission={this.props.currentSubmission}
                    pendingBorEnabled={pendingBorEnabled}
                    featureFlags={this.props.featureFlags}
                    CONSTANTS={this.props.CONSTANTS}
                />
            </div>
        );

        return (
            <div className="left-half">
                <ValidationRefetchWrapper />
                <PagedTableContainer
                    noRefetchOnFail={true}
                    tableData={this.props.tableData}
                    selectedRowId={this.props.currentSubmissionId}
                    onRowSelected={this.props.onRowSelected}
                    extraCellRenderData={{ me, ARCH_BASE_URL: ENV.ARCH_BASE_URL, FEATURE_FLAGS: featureFlags, CONSTANTS: this.props.CONSTANTS as any }}
                    tableConfig={tableConfig}
                    allowsDragging={false}
                    hasSearchPanel={true}
                    extraSearchComponent={this.renderSubmissionAssignmentControl()}
                    actionPanelContainerClass="left-panel-actions"
                    containerClass={null}
                    bottomPanels={bottomPanels}
                    bottomPanel={{
                        ContentElement: BottomPanelContainer,
                        currentSubmission,
                        locations,
                        label: 'Details',
                        className: 'max-bottom-panel vmac',
                        uiState: uiState,
                    }}
                    aboveGridContent={platformTogglerPanel}
                    belowGridContent={rowActions}
                    extraSettingsActions={settingsActions}
                    showSaveViewSettingsInSettingsPanel={true}
                    settingsMenuLabel="Table Menu"
                    resizableTable={true}
                />
            </div>
        );
    }
}
