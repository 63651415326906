import { useGetDuplicatesQuery } from '../../services/apiSlice';
import api from '../../services/apiSlice';
import { usePusherEvent } from '@archinsurance-viki/property-jslib/src/hooks/pusher';
import { useAppDispatch } from '../../hooks/redux';
import { SubmissionType } from '../../ts-types/DataTypes';
import { CLEARANCE_STATUS } from '../../constants/Constants';
import Tooltip from '@archinsurance-viki/property-jslib/src/components/widgets/Tooltip';

const getButton = (numberOfDupes: string, tooltipMessage: string = null, btnText: string = null) => {
    const text = !btnText ? 'Duplicates / Conflicts' : btnText;
    return (
        <Tooltip content={tooltipMessage}>
            <div className="flex">
                <div className="text">{text}</div>
                <div className="number purple-txt" data-testid="numberOfDupes">
                    {numberOfDupes}
                </div>
            </div>
        </Tooltip>
    );
};

type propTypes = {
    currentSubmissionId: number;
};
const ButtonWithDuplicates = (props: propTypes) => {
    const dispatch = useAppDispatch();

    usePusherEvent(`SUBMISSIONS-${props.currentSubmissionId}`, 'conflicts-dirty', _message => {
        console.warn(`invalidating duplicates for submission ${props.currentSubmissionId}`);
        dispatch(api.util.invalidateTags([{ type: 'SubmissionDuplicates', id: +props.currentSubmissionId }]));
    });

    const { data: duplicateData, isFetching } = useGetDuplicatesQuery({ submissionId: props.currentSubmissionId });
    const numberOfDupes = isFetching ? <div className="progress-indicator inline number" /> : duplicateData?.length;
    return getButton(numberOfDupes);
};

type duplicateSubmissionButtonPropTypes = {
    currentSubmission: SubmissionType;
    enableConflictAPICallOptimizationFF: boolean;
};

const DuplicateSubmissionButton = (props: duplicateSubmissionButtonPropTypes) => {
    if (props.enableConflictAPICallOptimizationFF === true) {
        return getButton('-', null, 'Click to View Duplicates/Conflicts');
    }

    const currentSubmission = props.currentSubmission;

    const currentSubmissionId = currentSubmission.id;
    if (!currentSubmissionId) {
        return getButton('-', 'No submission selected');
    }

    const isUnprocessed = currentSubmission.status === CLEARANCE_STATUS.UNPROCESSED && currentSubmission.is_archived === false;
    if (!isUnprocessed) {
        return getButton('-', 'Submission is already processed');
    }

    return <ButtonWithDuplicates currentSubmissionId={currentSubmissionId} />;
};

export default DuplicateSubmissionButton;
